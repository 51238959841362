var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { width: "100%" },
      attrs: { data: _vm.tableData, border: "", "tooltip-effect": "light" },
    },
    [
      _c("el-table-column", {
        attrs: {
          width: "490",
          prop: "tbdResumeId",
          align: "left",
          "show-overflow-tooltip": true,
          resizable: false,
          label: "简历ID",
        },
      }),
      _c("el-table-column", {
        attrs: {
          width: "118",
          prop: "realName",
          align: "left",
          "show-overflow-tooltip": true,
          resizable: false,
          label: "姓名",
        },
      }),
      _c("el-table-column", {
        attrs: {
          width: "108",
          prop: "company",
          align: "left",
          "show-overflow-tooltip": true,
          resizable: false,
          label: "公司",
        },
      }),
      _c("el-table-column", {
        attrs: {
          width: "108",
          prop: "title",
          align: "left",
          "show-overflow-tooltip": true,
          resizable: false,
          label: "职位",
        },
      }),
      _c("el-table-column", {
        attrs: {
          width: "108",
          prop: "recommendationCount",
          align: "left",
          "show-overflow-tooltip": true,
          resizable: false,
          label: "推荐",
        },
      }),
      _c("el-table-column", {
        attrs: {
          width: "108",
          prop: "inquiryLogCount",
          align: "left",
          "show-overflow-tooltip": true,
          resizable: false,
          label: "寻访",
        },
      }),
      _c("el-table-column", {
        attrs: {
          width: "108",
          prop: "belongerName",
          align: "left",
          "show-overflow-tooltip": true,
          resizable: false,
          label: "归属者",
        },
      }),
      _c("el-table-column", {
        attrs: { width: "50", align: "left", resizable: false, label: "操作" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "span",
                  {
                    staticClass: "delete-btn",
                    on: {
                      click: function ($event) {
                        return _vm.deleteRow(scope.row.tbdResumeId)
                      },
                    },
                  },
                  [_vm._v("移除")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }