var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm._m(0),
      _c("el-input", {
        staticStyle: { margin: "12px 0" },
        attrs: {
          type: "text",
          placeholder: "请输入接收地址，若未填写则不推送预警消息",
          maxlength: "200",
          "show-word-limit": "",
        },
        model: {
          value: _vm.form.webhookUrl,
          callback: function ($$v) {
            _vm.$set(
              _vm.form,
              "webhookUrl",
              typeof $$v === "string" ? $$v.trim() : $$v
            )
          },
          expression: "form.webhookUrl",
        },
      }),
      _vm._m(1),
      _c(
        "el-checkbox-group",
        {
          staticClass: "behavior-checkbox-group",
          model: {
            value: _vm.form.warningBehavior,
            callback: function ($$v) {
              _vm.$set(_vm.form, "warningBehavior", $$v)
            },
            expression: "form.warningBehavior",
          },
        },
        _vm._l(_vm.checkBoxOptions, function (it, idx) {
          return _c(
            "el-checkbox",
            { key: "cp_" + idx, attrs: { label: it.value } },
            [_vm._v(_vm._s(it.label) + "\n        ")]
          )
        }),
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "title" }, [
      _c("span"),
      _vm._v("推送预警消息接收地址"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "title" }, [_c("span"), _vm._v("预警行为")])
  },
]
render._withStripped = true

export { render, staticRenderFns }