<template>
    <div class="resume-hide-wrapper">
        <h3 class="title"><span></span>要隐藏的简历</h3>
        <div class="search-area">
            <el-input
                class="input"
                v-model="resumeId"
                placeholder="请输入您要操作的简历ID"
            ></el-input>
            <el-button class="button" type="primary" @click="handleSearch" v-loading="btnLoading"
                >搜索
            </el-button>
        </div>
        <el-table
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%"
            tooltip-effect="light"
        >
            <el-table-column
                width="360"
                prop="tbdResumeId"
                align="left"
                :show-overflow-tooltip="true"
                :resizable="false"
                label="简历ID"
            >
            </el-table-column>
            <el-table-column
                width="118"
                prop="realName"
                align="left"
                :show-overflow-tooltip="true"
                :resizable="false"
                label="姓名"
            >
            </el-table-column>
            <el-table-column
                width="108"
                prop="company"
                align="left"
                :show-overflow-tooltip="true"
                :resizable="false"
                label="公司"
            >
            </el-table-column>
            <el-table-column
                width="108"
                prop="title"
                align="left"
                :show-overflow-tooltip="true"
                :resizable="false"
                label="职位"
            >
            </el-table-column>
            <el-table-column
                width="108"
                prop="belongerName"
                align="left"
                :show-overflow-tooltip="true"
                :resizable="false"
                label="归属者"
            >
            </el-table-column>
            <el-table-column
                width="240"
                align="left"
                :show-overflow-tooltip="true"
                :resizable="false"
                label="隐藏时间"
            >
                <template slot-scope="scope">
                    <span>{{ formatHideTime(scope.row) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                width="100"
                align="left"
                :show-overflow-tooltip="true"
                :resizable="false"
                label="操作"
            >
                <template slot-scope="scope">
                    <span class="remove-btn" @click="relieveHide(scope.$index, scope.row)"
                        >解除隐藏
                    </span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import resumeMergeService from "#/js/service/resumeMerge.js";
export default {
    data() {
        return {
            loading: false,
            btnLoading: false,
            resumeId: "",
            tableData: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        formatHideTime(resume) {
            return resume.startTime.slice(0, 10) + "——" + resume.endTime.slice(0, 10);
        },
        getList() {
            this.loading = true;
            resumeMergeService
                .getHideResumeList()
                .then((res) => {
                    this.tableData = res || [];
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleSearch() {
            if (this.btnLoading) {
                return false;
            }
            // if(!this.resumeId) {
            //     shortTips('请输入简历ID');
            //     return false;
            // }
            if (this.resumeId.length > 50) {
                shortTips("简历ID不合法");
                return false;
            }
            let param = {};
            if (this.resumeId) {
                param.tbdResumeId = this.resumeId;
            }
            this.loading = true;
            this.btnLoading = true;
            resumeMergeService
                .getHideResumeList(param)
                .then((res) => {
                    this.tableData = res || [];
                })
                .finally(() => {
                    this.loading = false;
                    this.btnLoading = false;
                });
        },
        relieveHide(index, resume) {
            let self = this;
            this.loading = true;
            resumeMergeService
                .relieveHide({
                    tbdResumeId: resume.tbdResumeId,
                })
                .then((res) => {
                    let tableData = self.tableData;
                    tableData.splice(index, 1);
                    self.tableData = tableData;
                    shortTips("解除隐藏成功!");
                })
                .finally(() => {
                    self.loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scope>
.resume-hide-wrapper {
    // margin: 0 30px;
    padding: 30px 20px;
    .title {
        padding-top: 20px;
        padding-bottom: 14px;
        margin: 0;
        font-size: 18px;
        color: #333;
        line-height: 26px;

        span {
            display: inline-block;
            width: 6px;
            height: 26px;
            background-color: $primary;
            margin-right: 10px;
            vertical-align: top;
        }
    }

    .search-area {
        margin-bottom: 30px;

        .input {
            display: inline-block;
            width: 575px;
            height: 40px;
            line-height: 40px;
            margin-right: 20px;
            margin-bottom: 17px;
        }

        .button {
            width: 102px;
            height: 40px;
            line-height: 40px;
            color: $primary;
            border: 1px solid $primary;
            background-color: #f5f5f5;
        }
    }

    .remove-btn {
        color: $primary;
        cursor: pointer;
    }
}
</style>
