var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "resume-setting" },
    [
      _c("lbd-tabs", {
        attrs: { tabs: _vm.tabs, defaultActive: _vm.defaultActive },
        on: { "tab-click": _vm.handleTabClick },
      }),
      _c("div", { staticClass: "main-c" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTab == "resumeMerge",
                expression: "activeTab == 'resumeMerge'",
              },
            ],
            staticClass: "resume-merge",
          },
          [
            _c(
              "div",
              { staticClass: "resume-merge-main" },
              [
                _c(
                  "div",
                  { staticClass: "link" },
                  [
                    _c("router-link", { attrs: { to: "/resumeMergeLog" } }, [
                      _vm._v("合并日志"),
                    ]),
                  ],
                  1
                ),
                _vm._m(0),
                _c("el-input", {
                  staticClass: "input",
                  attrs: { placeholder: "请输入要删除的简历ID" },
                  model: {
                    value: _vm.deleteId,
                    callback: function ($$v) {
                      _vm.deleteId = $$v
                    },
                    expression: "deleteId",
                  },
                }),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.deleteBtnLoading,
                        expression: "deleteBtnLoading",
                      },
                    ],
                    staticClass: "button",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleDelete },
                  },
                  [_vm._v("添加\n                ")]
                ),
                _c("resume-merge-table", {
                  staticClass: "table",
                  attrs: { tableData: _vm.deleteData, type: "delete" },
                  on: { removeRow: _vm.handleRemoveDeleteRow },
                }),
                _vm._m(1),
                _c("el-input", {
                  staticClass: "input",
                  attrs: { placeholder: "请输入要留下的简历ID" },
                  model: {
                    value: _vm.keepId,
                    callback: function ($$v) {
                      _vm.keepId = $$v
                    },
                    expression: "keepId",
                  },
                }),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.keepBtnLoading,
                        expression: "keepBtnLoading",
                      },
                    ],
                    staticClass: "button",
                    attrs: { type: "primary", disabled: _vm.keepBtnDisabled },
                    on: { click: _vm.handleKeep },
                  },
                  [_vm._v("确定\n                ")]
                ),
                _c("resume-merge-table", {
                  staticClass: "table",
                  attrs: { tableData: _vm.keepData, type: "keep" },
                  on: { removeRow: _vm.handleRemoveKeepRow },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "resume-merge-btn" },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.mergeBtnLoading,
                        expression: "mergeBtnLoading",
                      },
                    ],
                    staticClass: "btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleShowMergeDialog },
                  },
                  [_vm._v("合并\n                ")]
                ),
                _c("span", { staticClass: "resume-merge-btn-tip" }, [
                  _vm._v(
                    "\n                    合并后，将会把要删除的简历的【寻访、推荐记录、附件】合并到要留下的简历。请确认要删除的简历的归属权已经更改。\n                "
                  ),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "resume-merge-footer" }, [
              _vm._v("猎必得 liebide.com"),
            ]),
            _c(
              "el-dialog",
              {
                attrs: {
                  "custom-class": "merge-dialog",
                  title: "提示",
                  visible: _vm.mergeConfirmDialogVisible,
                  width: "480px",
                  "show-close": false,
                  "close-on-click-modal": false,
                  "close-on-press-escape": false,
                },
              },
              [
                _c("span", { staticClass: "merge-dialog-text" }, [
                  _vm._v(
                    "\n                    确定合并吗？请确认好要删除的简历和要留下的简历ID输入正确，请确认要删除的简历的归属权已经更改。"
                  ),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c("el-button", { on: { click: _vm.handleCancelMerge } }, [
                      _vm._v("取消"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleMerge },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  "custom-class": "merge-dialog",
                  title: "提示",
                  visible: _vm.mergeSuccessDialogVisible,
                  width: "480px",
                  "show-close": false,
                  "close-on-click-modal": false,
                  "close-on-press-escape": false,
                },
              },
              [
                _c("span", { staticClass: "merge-dialog-text" }, [
                  _vm._v("合并成功，预计将在10分钟内生效"),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      { on: { click: _vm.handleCloseSuccessDialog } },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleJumpSuccessDetail },
                      },
                      [_vm._v("去查看")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTab == "resumeHide",
                expression: "activeTab == 'resumeHide'",
              },
            ],
            staticClass: "resume-hide",
          },
          [_c("resume-hide")],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTab == "resumeSetting",
                expression: "activeTab == 'resumeSetting'",
              },
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.pageLoading,
                expression: "pageLoading",
              },
            ],
            staticClass: "resume-merge",
          },
          [
            _c("div", { staticClass: "resume-merge-main" }, [
              _vm._m(2),
              _c("div", { staticClass: "setting-block" }, [
                _c("div", { staticClass: "resume-merge-main-block" }, [
                  _c("span", [_vm._v("简历的附件仅展示最近")]),
                  !_vm.isEditStatus
                    ? _c("span", { staticClass: "count" }, [
                        _vm._v(_vm._s(_vm.viewMonth)),
                      ])
                    : _c(
                        "div",
                        { staticClass: "input-number-box" },
                        [
                          _c("el-input-number", {
                            attrs: {
                              size: "small",
                              precision: 0,
                              step: 1,
                              max: 99,
                            },
                            model: {
                              value: _vm.viewMonth,
                              callback: function ($$v) {
                                _vm.viewMonth = $$v
                              },
                              expression: "viewMonth",
                            },
                          }),
                          _c(
                            "span",
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-information-circle" },
                              }),
                              _vm._v(
                                "\n                                填写0为全部展示\n                            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c("span", [_vm._v("个月的附件内容")]),
                ]),
                _c("div", { staticClass: "resume-merge-main-block" }, [
                  _c("span", [_vm._v("候选人简历附件更新时间在")]),
                  !_vm.isEditStatus
                    ? _c("span", { staticClass: "count" }, [
                        _vm._v(_vm._s(_vm.limitMonth)),
                      ])
                    : _c(
                        "div",
                        { staticClass: "input-number-box" },
                        [
                          _c("el-input-number", {
                            attrs: {
                              size: "small",
                              precision: 0,
                              step: 1,
                              max: 99,
                            },
                            model: {
                              value: _vm.limitMonth,
                              callback: function ($$v) {
                                _vm.limitMonth = $$v
                              },
                              expression: "limitMonth",
                            },
                          }),
                          _c(
                            "span",
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-information-circle" },
                              }),
                              _vm._v(
                                "\n                                填写0时，推荐候选人时必须上传附件\n                            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c("span", [
                    _vm._v("个月内，推荐该候选人时无需上传推荐附件简历"),
                  ]),
                ]),
              ]),
              _vm._m(3),
              _c("div", { staticClass: "setting-block" }, [
                _c("div", { staticClass: "resume-merge-main-block" }, [
                  _c("span", [_vm._v("简历联系电话修改频次：")]),
                  !_vm.isEditStatus
                    ? _c("span", { staticClass: "count" }, [
                        _vm._v(_vm._s(_vm.mobileEditDays)),
                      ])
                    : _c(
                        "div",
                        { staticClass: "input-number-box" },
                        [
                          _c("el-input-number", {
                            attrs: {
                              size: "small",
                              precision: 0,
                              step: 1,
                              max: 99,
                            },
                            model: {
                              value: _vm.mobileEditDays,
                              callback: function ($$v) {
                                _vm.mobileEditDays = $$v
                              },
                              expression: "mobileEditDays",
                            },
                          }),
                          _c(
                            "span",
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-information-circle" },
                              }),
                              _vm._v(
                                "\n                                填写0为不限制\n                            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c("span", [_vm._v("天内，简历联系电话修改不得超过")]),
                  !_vm.isEditStatus
                    ? _c("span", { staticClass: "count" }, [
                        _vm._v(_vm._s(_vm.mobileEditLimit)),
                      ])
                    : _c(
                        "div",
                        { staticClass: "input-number-box" },
                        [
                          _c("el-input-number", {
                            attrs: {
                              size: "small",
                              precision: 0,
                              step: 1,
                              max: 99,
                            },
                            model: {
                              value: _vm.mobileEditLimit,
                              callback: function ($$v) {
                                _vm.mobileEditLimit = $$v
                              },
                              expression: "mobileEditLimit",
                            },
                          }),
                          _c(
                            "span",
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-information-circle" },
                              }),
                              _vm._v(
                                "\n                                填写0为不限制\n                            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c("span", [_vm._v("次")]),
                ]),
                _c("div", { staticClass: "resume-merge-main-block" }, [
                  _c("span", [_vm._v("简历新姓名修改频次限定：")]),
                  !_vm.isEditStatus
                    ? _c("span", { staticClass: "count" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.resumeNameUpdateValidityDay) +
                            "\n                        "
                        ),
                      ])
                    : _c(
                        "div",
                        { staticClass: "input-number-box" },
                        [
                          _c("el-input-number", {
                            attrs: {
                              size: "small",
                              precision: 0,
                              step: 1,
                              max: 99,
                            },
                            model: {
                              value: _vm.resumeNameUpdateValidityDay,
                              callback: function ($$v) {
                                _vm.resumeNameUpdateValidityDay = $$v
                              },
                              expression: "resumeNameUpdateValidityDay",
                            },
                          }),
                          _c(
                            "span",
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-information-circle" },
                              }),
                              _vm._v("填写0为不限制"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c("span", [_vm._v("天内，简历姓名修改不得超过")]),
                  !_vm.isEditStatus
                    ? _c("span", { staticClass: "count" }, [
                        _vm._v(_vm._s(_vm.resumeNameUpdateCount)),
                      ])
                    : _c(
                        "div",
                        { staticClass: "input-number-box" },
                        [
                          _c("el-input-number", {
                            attrs: {
                              size: "small",
                              precision: 0,
                              step: 1,
                              max: 99,
                            },
                            model: {
                              value: _vm.resumeNameUpdateCount,
                              callback: function ($$v) {
                                _vm.resumeNameUpdateCount = $$v
                              },
                              expression: "resumeNameUpdateCount",
                            },
                          }),
                          _c(
                            "span",
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-information-circle" },
                              }),
                              _vm._v(
                                "\n                                填写0为不限制\n                            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c("span", [_vm._v("次")]),
                ]),
                _c("div", { staticClass: "resume-merge-main-block" }, [
                  _c("span", [_vm._v("简历更新频次限定：")]),
                  !_vm.isEditStatus
                    ? _c("span", { staticClass: "count" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.resumeUpdateValidityDay) +
                            "\n                        "
                        ),
                      ])
                    : _c(
                        "div",
                        { staticClass: "input-number-box" },
                        [
                          _c("el-input-number", {
                            attrs: {
                              size: "small",
                              precision: 0,
                              step: 1,
                              max: 99,
                            },
                            model: {
                              value: _vm.resumeUpdateValidityDay,
                              callback: function ($$v) {
                                _vm.resumeUpdateValidityDay = $$v
                              },
                              expression: "resumeUpdateValidityDay",
                            },
                          }),
                          _c(
                            "span",
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-information-circle" },
                              }),
                              _vm._v("填写0为不限制"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c("span", [_vm._v("天内，简历更新不得修改超过")]),
                  !_vm.isEditStatus
                    ? _c("span", { staticClass: "count" }, [
                        _vm._v(_vm._s(_vm.resumeUpdateCount)),
                      ])
                    : _c(
                        "div",
                        { staticClass: "input-number-box" },
                        [
                          _c("el-input-number", {
                            attrs: {
                              size: "small",
                              precision: 0,
                              step: 1,
                              max: 99,
                            },
                            model: {
                              value: _vm.resumeUpdateCount,
                              callback: function ($$v) {
                                _vm.resumeUpdateCount = $$v
                              },
                              expression: "resumeUpdateCount",
                            },
                          }),
                          _c(
                            "span",
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-information-circle" },
                              }),
                              _vm._v(
                                "\n                                填写0为不限制\n                            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c("span", [_vm._v("次")]),
                ]),
              ]),
              _vm._m(4),
              _c("div", { staticClass: "setting-block" }, [
                !_vm.isEditStatus
                  ? _c(
                      "div",
                      { staticClass: "tags-wrap" },
                      [
                        _vm.invalidWordsArr.length == 0
                          ? _c("span", [_vm._v("暂未设置")])
                          : _vm._l(_vm.invalidWordsArr, function (tag, index) {
                              return _c(
                                "el-tag",
                                {
                                  key: "tag_11_" + index,
                                  staticClass: "my-tag",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(tag) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "block-wp" }, [
                      _c(
                        "div",
                        { staticClass: "bw-top" },
                        [
                          _vm.invalidWordsArr.length == 0
                            ? _c(
                                "el-tag",
                                {
                                  staticClass: "my-tag",
                                  attrs: { "disable-transitions": false },
                                },
                                [
                                  _vm._v(
                                    "\n                                暂未设置姓名无效词\n                            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._l(_vm.invalidWordsArr, function (tag, index) {
                            return _c(
                              "el-tag",
                              {
                                key: "tag_1_" + index,
                                staticClass: "my-tag",
                                attrs: {
                                  closable: "",
                                  "disable-transitions": false,
                                },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleTagClose(
                                      tag,
                                      "invalidWords"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(tag) +
                                    "\n                            "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "bw-bottom" },
                        [
                          _c("el-input", {
                            staticClass: "tag-input",
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 4, maxRows: 7 },
                              placeholder:
                                "请输入候选人姓名无效词，按回车键提交",
                              maxlength: "40",
                              "show-word-limit": "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleInvalidWordsInputConfirm.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.inputInvalidWordstValue,
                              callback: function ($$v) {
                                _vm.inputInvalidWordstValue =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "inputInvalidWordstValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
              ]),
              _vm._m(5),
              _c("div", { staticClass: "setting-block" }, [
                !_vm.isEditStatus
                  ? _c(
                      "div",
                      { staticClass: "tags-wrap" },
                      [
                        _vm.blackListArr.length == 0
                          ? _c("span", [_vm._v("暂未设置")])
                          : _vm._l(_vm.blackListArr, function (tag, index) {
                              return _c(
                                "el-tag",
                                {
                                  key: "tag_21_" + index,
                                  staticClass: "my-tag",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(tag) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "block-wp" }, [
                      _c(
                        "div",
                        { staticClass: "bw-top" },
                        [
                          _vm.blackListArr.length == 0
                            ? _c(
                                "el-tag",
                                {
                                  staticClass: "my-tag",
                                  attrs: { "disable-transitions": false },
                                },
                                [
                                  _vm._v(
                                    "\n                                暂未设置手机号黑名单\n                            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._l(_vm.blackListArr, function (tag, index) {
                            return _c(
                              "el-tag",
                              {
                                key: "tag_2_" + index,
                                staticClass: "my-tag",
                                attrs: {
                                  closable: "",
                                  "disable-transitions": false,
                                },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleTagClose(tag, "blackList")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(tag) +
                                    "\n                            "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "bw-bottom" },
                        [
                          _c("el-input", {
                            staticClass: "tag-input",
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 4, maxRows: 7 },
                              placeholder: "候选人手机号黑名单，按回车键提交",
                              maxlength: "40",
                              "show-word-limit": "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleBlackListInputConfirm.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.inputBlackListValue,
                              callback: function ($$v) {
                                _vm.inputBlackListValue =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "inputBlackListValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
              ]),
              _vm._m(6),
              _c("div", { staticClass: "setting-block" }, [
                !_vm.isEditStatus
                  ? _c(
                      "div",
                      { staticClass: "tags-wrap" },
                      [
                        _vm.whiteListArr.length == 0
                          ? _c("span", [_vm._v("暂未设置")])
                          : _vm._l(_vm.whiteListArr, function (tag, index) {
                              return _c(
                                "el-tag",
                                {
                                  key: "tag_21_" + index,
                                  staticClass: "my-tag",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(tag) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "block-wp" }, [
                      _c(
                        "div",
                        { staticClass: "bw-top" },
                        [
                          _vm.whiteListArr.length == 0
                            ? _c(
                                "el-tag",
                                {
                                  staticClass: "my-tag",
                                  attrs: { "disable-transitions": false },
                                },
                                [
                                  _vm._v(
                                    "\n                                暂未设置公司白名单\n                            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._l(_vm.whiteListArr, function (tag, index) {
                            return _c(
                              "el-tag",
                              {
                                key: "tag_2_" + index,
                                staticClass: "my-tag",
                                attrs: {
                                  closable: "",
                                  "disable-transitions": false,
                                },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleTagClose(tag, "whiteList")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(tag) +
                                    "\n                            "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "bw-bottom" },
                        [
                          _c("el-input", {
                            staticClass: "tag-input",
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 4, maxRows: 7 },
                              placeholder: "公司白名单，按回车键提交",
                              maxlength: "40",
                              "show-word-limit": "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleWhiteListInputConfirm.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.inputWhiteListValue,
                              callback: function ($$v) {
                                _vm.inputWhiteListValue =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "inputWhiteListValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
              ]),
              _c(
                "div",
                { staticClass: "resume-merge-main-btns" },
                [
                  _vm.isEditStatus
                    ? _c(
                        "el-button",
                        { on: { click: _vm.handleCancelEditResumeSetting } },
                        [_vm._v("取消\n                    ")]
                      )
                    : _c(
                        "el-button",
                        { on: { click: _vm.handleEditResumeSetting } },
                        [_vm._v("编辑")]
                      ),
                  _vm.isEditStatus
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.resumeSaveBtnLoading,
                              expression: "resumeSaveBtnLoading",
                            },
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.submitResumeSettings },
                        },
                        [_vm._v("保存\n                    ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTab == "forewarning",
                expression: "activeTab == 'forewarning'",
              },
            ],
            staticClass: "fore-warning",
          },
          [_c("resume-update-fore-warning")],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "title" }, [
      _c("span"),
      _vm._v("要删除的简历"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "title" }, [
      _c("span"),
      _vm._v("要留下的简历"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "title" }, [_c("span"), _vm._v("附件简历")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "title" }, [
      _c("span"),
      _vm._v("简历信息编辑"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "title" }, [
      _c("span"),
      _vm._v("简历候选人姓名无效词设置"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "title" }, [
      _c("span"),
      _vm._v("候选人手机号黑名单"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "title" }, [
      _c("span"),
      _vm._v("公司白名单"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }