<!--  -->
<template>
    <div class="container">
        <h3 class="title"><span></span>推送预警消息接收地址</h3>
        <el-input
            style="margin: 12px 0"
            type="text"
            v-model.trim="form.webhookUrl"
            placeholder="请输入接收地址，若未填写则不推送预警消息"
            maxlength="200"
            show-word-limit
        ></el-input>

        <h3 class="title"><span></span>预警行为</h3>
        <el-checkbox-group v-model="form.warningBehavior" class="behavior-checkbox-group">
            <el-checkbox v-for="(it, idx) in checkBoxOptions" :key="'cp_' + idx" :label="it.value"
                >{{ it.label }}
            </el-checkbox>
        </el-checkbox-group>

        <el-row style="margin-top: 20px">
            <el-col :span="24">
                <el-button type="primary" @click="onSubmit" :loading="btnLoading">保 存</el-button>
            </el-col>
        </el-row>

        <!-- <el-form ref="form" :model="form" label-width="280px" label-position="top">
            <el-form-item label="推送预警消息接收地址">
                <el-input
                    type="text"
                    v-model.trim="form.webhookUrl"
                    placeholder="请输入预警消息接收地址"
                    maxlength="200"
                    show-word-limit
                ></el-input>
            </el-form-item>
            <el-form-item label="预警行为">
                <el-checkbox-group v-model="form.warningBehavior" class="behavior-checkbox-group">
                    <el-checkbox
                        v-for="(it, idx) in checkBoxOptions"
                        :key="'cp_' + idx"
                        :label="it.value"
                        >{{ it.label }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit" :loading="btnLoading">保 存</el-button>
            </el-form-item>
        </el-form> -->
    </div>
</template>

<script>
//导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等）
//例如：import 组件名称 from '组件路径';
import resumeMergeService from "#/js/service/resumeMerge.js";

export default {
    name: "resume-update-fore-warning",
    components: {},
    data() {
        return {
            checkBoxOptions: [
                {
                    label: "对候选人姓名进行变更",
                    value: "1",
                },
                // {
                //     label: "对候选人变更的新手机号3个月内在其他简历中拨打过电话",
                //     value: "2",
                // },
                {
                    label: "一次候选人简历编辑中5次输入异常的手机号码",
                    value: "3",
                },
                {
                    label: "对候选人出生日期异常修改",
                    value: "4",
                },
                {
                    label: "对候选人某项教育信息进行修改",
                    value: "5",
                },
                {
                    label: "对候选人某项工作经历进行修改",
                    value: "6",
                },
            ],
            form: {
                webhookUrl: "",
                warningBehavior: [],
            },
            settingId: "",
            btnLoading: false,
        };
    },
    // 生命周期 - DOM元素渲染前调用
    created() {
        this.fetchResumeSettings();
    },
    // 生命周期 - DOM元素渲染后调用
    mounted() {},
    // 计算属性（类似于data概念）
    computed: {},
    // 监听器（监控data中的数据变化）
    watch: {},
    // 方法集合
    methods: {
        onSubmit() {
            console.log("submit!");
            this.holdResumeSettings();
        },

        fetchResumeSettings() {
            resumeMergeService.getResumeSettings().then((res) => {
                this.settingId = res.id || "";
                this.form.webhookUrl = res.webhookUrl || "";
                this.form.warningBehavior =
                    res.warningBehavior.split(",").filter((it) => it != "") || [];
            });
        },

        holdResumeSettings() {
            const params = {
                id: this.settingId,
                webhookUrl: this.form.webhookUrl,
                warningBehavior: this.form.warningBehavior.join(",") || "",
            };
            this.btnLoading = true;
            resumeMergeService
                .saveResumeSettings(params)
                .then((res) => {
                    this.$message.success("保存成功");
                })
                .finally(() => {
                    this.btnLoading = false;
                });
        },
    },
};
</script>
<style lang="scss" scoped>
/* @import url(); 引入公共css类 */
.title {
    padding-top: 20px;
    padding-bottom: 14px;
    margin: 0;
    font-size: 18px;
    color: #333;
    line-height: 26px;

    span {
        display: inline-block;
        width: 6px;
        height: 26px;
        background-color: $primary;
        margin-right: 10px;
        vertical-align: top;
    }
}
.behavior-checkbox-group {
    display: flex;
    flex-direction: column;
    .el-checkbox {
        display: block;
        margin: 12px 0;
    }
}
</style>
