<template>
    <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        tooltip-effect="light">
        <el-table-column
            width="490"
            prop="tbdResumeId"
            align="left"
            :show-overflow-tooltip="true"
            :resizable="false"
            label="简历ID">
        </el-table-column>
        <el-table-column
            width="118"
            prop="realName"
            align="left"
            :show-overflow-tooltip="true"
            :resizable="false"
            label="姓名">
        </el-table-column>
        <el-table-column
            width="108"
            prop="company"
            align="left"
            :show-overflow-tooltip="true"
            :resizable="false"
            label="公司">
        </el-table-column>
        <el-table-column
            width="108"
            prop="title"
            align="left"
            :show-overflow-tooltip="true"
            :resizable="false"
            label="职位">
        </el-table-column>
        <el-table-column
            width="108"
            prop="recommendationCount"
            align="left"
            :show-overflow-tooltip="true"
            :resizable="false"
            label="推荐">
        </el-table-column>
        <el-table-column
            width="108"
            prop="inquiryLogCount"
            align="left"
            :show-overflow-tooltip="true"
            :resizable="false"
            label="寻访">
        </el-table-column>
        <el-table-column
            width="108"
            prop="belongerName"
            align="left"
            :show-overflow-tooltip="true"
            :resizable="false"
            label="归属者">
        </el-table-column>
        <el-table-column
            width="50"
            align="left"
            :resizable="false"
            label="操作">
                <template slot-scope="scope">
                    <span class="delete-btn" @click="deleteRow(scope.row.tbdResumeId)">移除</span>
                </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    name: 'resumeMergeTable',
    data() {
        return {
            loading: false
        }
    },
    props: {
        tableData: Array
    },
    methods: {
        deleteRow(tbdResumeId) {
            this.$emit('removeRow', tbdResumeId);
        }
    }
}
</script>

<style lang="scss" scoped>
    .delete-btn {
        color: #C91D1D;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
    /deep/ .el-table__empty-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
    }
</style>